import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Prizes: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 175 88" {...props}>
      <path
        d="M138.637 25.0412C138.483 22.1916 141.157 20.0227 143.914 20.7614C146.152 21.3612 147.481 23.6623 146.881 25.901L145.79 29.9737C146.515 30.1311 147.229 30.3253 147.931 30.5544L149.024 26.4751C149.623 24.2364 151.925 22.9079 154.163 23.5078C156.92 24.2464 158.151 27.4616 156.593 29.8524L154.072 33.7209C157.27 36.0948 159.748 39.3533 160.97 43.2037C162.041 46.578 162.004 49.9578 161.058 53.0226C160.968 53.8327 160.817 54.644 160.601 55.4518C160.087 57.3696 159.245 59.121 158.142 60.6719L163.174 69.1556C163.634 69.9316 163.001 70.8794 162.095 70.7694L156.871 70.1348C156.384 70.0757 155.919 70.337 155.728 70.7772L153.68 75.4965C153.325 76.3148 152.165 76.375 151.704 75.599L147.08 67.8027C143.883 68.5165 140.402 68.4826 136.929 67.5519C133.481 66.6281 130.468 64.9353 128.065 62.7425L120.204 67.1586C119.418 67.6005 118.443 66.9682 118.545 66.082L119.131 60.971C119.185 60.4943 118.913 60.0354 118.462 59.843L113.621 57.7808C112.781 57.4232 112.706 56.2855 113.493 55.8436L122.004 51.0621C121.814 49.1436 121.957 47.1801 122.478 45.2369C122.594 44.8036 122.727 44.3788 122.876 43.9629C123.125 42.3671 123.613 40.8307 124.315 39.3936L124.313 39.3912C125.253 37.4561 126.561 35.7521 128.141 34.3272C129.971 32.6704 132.209 31.3535 134.772 30.5104C135.271 30.3451 135.78 30.1988 136.296 30.0722L136.298 30.0746C136.434 30.0414 136.571 30.0095 136.709 29.9789C137.433 29.8182 138.158 29.6977 138.884 29.6157L138.637 25.0412Z"
        stroke="#652F05"
        strokeWidth="3"
      />
      <path
        d="M156.655 29.8692C158.213 27.4783 156.982 24.2631 154.225 23.5245C151.987 22.9247 149.686 24.2532 149.086 26.4919L147.084 33.9625C146.66 35.5444 147.599 37.1703 149.181 37.5941C150.42 37.9261 151.732 37.4237 152.432 36.3489L156.655 29.8692Z"
        fill="#F6AF5B"
      />
      <path
        d="M138.699 25.058C138.545 22.2084 141.219 20.0396 143.976 20.7782C146.214 21.3781 147.543 23.6791 146.943 25.9178L144.941 33.3885C144.517 34.9703 142.892 35.909 141.31 35.4852C140.071 35.1531 139.186 34.0619 139.116 32.781L138.699 25.058Z"
        fill="#F6AF5B"
      />
      <path
        d="M151.766 75.6155L139.039 54.1587C138.73 53.638 138.908 52.9754 139.436 52.6789L148.994 47.3094C149.522 47.0129 150.2 47.1946 150.509 47.7154L163.236 69.1722C163.696 69.9481 163.062 70.8959 162.157 70.7859L156.933 70.1514C156.446 70.0922 155.981 70.3535 155.79 70.7937L153.742 75.513C153.387 76.3313 152.227 76.3915 151.766 75.6155Z"
        fill="#AE5714"
      />
      <path
        d="M120.267 67.175L142.017 54.9564C142.545 54.6598 142.722 53.9973 142.413 53.4765L136.821 44.0474C136.512 43.5266 135.833 43.3449 135.305 43.6414L113.555 55.86C112.769 56.3019 112.844 57.4397 113.683 57.7972L118.524 59.8594C118.975 60.0518 119.248 60.5107 119.193 60.9874L118.607 66.0984C118.505 66.9846 119.48 67.6169 120.267 67.175Z"
        fill="#AE5714"
      />
      <ellipse
        cx="141.601"
        cy="50.3614"
        rx="19.7337"
        ry="17.8146"
        transform="rotate(15 141.601 50.3614)"
        fill="#E17A2F"
      />
      <ellipse
        rx="19.1997"
        ry="18.2425"
        transform="matrix(0.976209 -0.216833 0.302485 0.953154 142.289 47.384)"
        fill="#F6AF5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.358 30.0894L159.52 56.6194C158.88 57.6855 158.121 58.6694 157.264 59.5618L132.67 31.3905C133.837 30.8414 135.073 30.4044 136.358 30.0894ZM128.367 34.1983L153.245 62.683C151.271 63.811 149.058 64.5977 146.714 64.9874L124.375 39.4084C125.348 37.4067 126.713 35.6523 128.367 34.1983Z"
        fill="#F7D29B"
      />
      <ellipse
        rx="14.6705"
        ry="13.9392"
        transform="matrix(0.976209 -0.216833 0.302485 0.953154 142.289 47.3834)"
        fill="#E7974D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M156.931 45.7446C156.833 45.2429 156.704 44.7396 156.544 44.2363C154.192 36.8638 145.904 32.2962 138.032 34.0342C130.159 35.7723 125.683 43.1578 128.034 50.5303C128.191 51.0212 128.374 51.4996 128.581 51.9646C127.244 45.1183 131.63 38.5962 138.966 36.9768C146.314 35.3544 154.024 39.2261 156.931 45.7446Z"
        fill="#E17A2F"
      />
      <path
        d="M136.093 48.8468C134.099 48.4066 133.804 46.621 134.246 44.5963C134.688 42.5716 135.701 41.0746 137.695 41.5147C139.689 41.9548 139.984 43.7405 139.542 45.7652C139.1 47.7898 138.087 49.2869 136.093 48.8468Z"
        fill="#AE5714"
      />
      <path
        d="M147.168 51.815C145.174 51.3749 144.879 49.5893 145.321 47.5646C145.764 45.5399 146.776 44.0428 148.77 44.483C150.764 44.9231 151.059 46.7087 150.617 48.7334C150.175 50.7581 149.162 52.2552 147.168 51.815Z"
        fill="#AE5714"
      />
      <path
        d="M18.1655 29.8861C16.6074 27.4952 17.8386 24.28 20.5952 23.5414C22.8338 22.9415 25.1349 24.2701 25.7348 26.5087L26.8261 30.5814C27.5328 30.3552 28.2488 30.166 28.9706 30.0138L27.8775 25.9346C27.2777 23.6959 28.6062 21.3948 30.8449 20.795C33.6014 20.0564 36.2753 22.2252 36.1214 25.0748L35.8724 29.6854C39.8288 30.1424 43.6043 31.7252 46.5877 34.4488C49.2022 36.8356 50.8606 39.7808 51.573 42.9084C51.9008 43.6546 52.1754 44.4329 52.3918 45.2407C52.9057 47.1585 53.0524 49.0961 52.8727 50.9907L61.4725 55.8218C62.2591 56.2637 62.184 57.4014 61.3447 57.759L56.5037 59.8212C56.0522 60.0135 55.78 60.4724 55.8347 60.9491L56.4209 66.0601C56.5225 66.9463 55.5477 67.5786 54.7611 67.1367L46.8581 62.6971C44.4463 64.9138 41.4146 66.625 37.9413 67.5557C34.4937 68.4795 31.0384 68.5197 27.8608 67.8222L23.261 75.5771C22.8008 76.3531 21.6404 76.293 21.2853 75.4747L19.2375 70.7553C19.0465 70.3151 18.5813 70.0538 18.0941 70.113L12.8706 70.7476C11.9649 70.8576 11.331 69.9098 11.7913 69.1338L16.7717 60.7371C15.6472 59.1711 14.7899 57.3988 14.2692 55.4556C14.1531 55.0223 14.0558 54.588 13.9767 54.1534C13.3947 52.6468 13.049 51.0723 12.9382 49.4767L12.9352 49.4757C12.7824 47.3295 13.0627 45.2001 13.7188 43.1759C14.4755 40.8259 15.7549 38.5667 17.553 36.555C17.9027 36.1622 18.2699 35.7813 18.6538 35.4135L18.6567 35.4145C18.758 35.3177 18.8607 35.2216 18.9647 35.1263C19.5109 34.6254 20.0793 34.158 20.6668 33.7242L18.1655 29.8861Z"
        stroke="#4C4E54"
        strokeWidth="3"
      />
      <path
        d="M36.1836 25.058C36.3375 22.2084 33.6636 20.0396 30.9071 20.7782C28.6684 21.3781 27.3399 23.6791 27.9397 25.9178L29.9415 33.3885C30.3653 34.9703 31.9912 35.909 33.573 35.4852C34.8122 35.1531 35.6973 34.0619 35.7664 32.781L36.1836 25.058Z"
        fill="#C0C4C6"
      />
      <path
        d="M18.2277 29.8692C16.6696 27.4783 17.9009 24.2631 20.6574 23.5245C22.8961 22.9247 25.1971 24.2532 25.797 26.4919L27.7987 33.9626C28.2226 35.5444 27.2839 37.1703 25.7021 37.5941C24.4629 37.9261 23.1508 37.4237 22.4504 36.3489L18.2277 29.8692Z"
        fill="#C0C4C6"
      />
      <path
        d="M54.8233 67.1198L33.073 54.9012C32.5451 54.6047 32.3676 53.9421 32.6765 53.4213L38.2693 43.9922C38.5782 43.4714 39.2565 43.2897 39.7844 43.5862L61.5347 55.8049C62.3213 56.2467 62.2462 57.3845 61.4068 57.7421L56.5659 59.8043C56.1144 59.9966 55.8422 60.4555 55.8969 60.9322L56.483 66.0432C56.5847 66.9294 55.6099 67.5617 54.8233 67.1198Z"
        fill="#7D7D7D"
      />
      <path
        d="M23.3231 75.5603L36.0501 54.1036C36.359 53.5828 36.1814 52.9202 35.6536 52.6237L26.0954 47.2542C25.5676 46.9577 24.8892 47.1395 24.5804 47.6602L11.8534 69.117C11.3931 69.893 12.027 70.8408 12.9327 70.7307L18.1562 70.0962C18.6434 70.037 19.1086 70.2983 19.2996 70.7385L21.3474 75.4579C21.7025 76.2761 22.8629 76.3363 23.3231 75.5603Z"
        fill="#7D7D7D"
      />
      <ellipse
        cx="33.3928"
        cy="50.3315"
        rx="19.7337"
        ry="17.8146"
        transform="rotate(-15 33.3928 50.3315)"
        fill="#969696"
      />
      <ellipse
        rx="19.1997"
        ry="18.2425"
        transform="matrix(0.737005 -0.675888 0.738537 0.674213 32.4995 47.4094)"
        fill="#C0C4C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.716 35.397L52.0401 46.7918C52.0182 48.0354 51.8535 49.2666 51.5575 50.4679L16.1721 38.3682C16.9087 37.3088 17.7607 36.3123 18.716 35.397ZM13.8503 42.9508L49.637 55.1806C48.4919 57.1442 46.9685 58.9321 45.1337 60.4414L12.9977 49.4592C12.8396 47.2391 13.1449 45.037 13.8503 42.9508Z"
        fill="#D8D8D8"
      />
      <ellipse
        rx="14.6705"
        ry="13.9392"
        transform="matrix(0.737005 -0.675888 0.738537 0.674213 32.4994 47.4091)"
        fill="#B2B2B2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3599 38.6689C44.0243 38.2836 43.6612 37.912 43.2706 37.5565C37.5481 32.3473 28.0866 32.5357 22.1378 37.9772C16.1889 43.4187 16.0054 52.0528 21.7278 57.262C22.1089 57.6088 22.5065 57.9318 22.9186 58.2308C18.3374 52.9703 18.875 45.1286 24.4179 40.0586C29.9706 34.9794 38.5837 34.477 44.3599 38.6689Z"
        fill="#969696"
      />
      <path
        d="M27.8654 51.7741C25.9183 52.3901 24.7699 50.9912 24.1406 49.0166C23.5113 47.042 23.6395 45.2394 25.5865 44.6235C27.5336 44.0076 28.682 45.4064 29.3113 47.381C29.9406 49.3556 29.8124 51.1582 27.8654 51.7741Z"
        fill="#7D7D7D"
      />
      <path
        d="M38.9406 48.8073C36.9935 49.4232 35.8451 48.0244 35.2158 46.0498C34.5865 44.0752 34.7146 42.2726 36.6617 41.6567C38.6088 41.0408 39.7572 42.4396 40.3865 44.4142C41.0158 46.3888 40.8876 48.1914 38.9406 48.8073Z"
        fill="#7D7D7D"
      />
      <path
        d="M71.8819 11.3215C70.4503 6.93951 73.7157 2.4375 78.3256 2.4375C82.0695 2.4375 85.1046 5.47253 85.1046 9.21642L85.1046 16.0275C86.3018 15.97 87.4983 15.9741 88.688 16.0384V9.21642C88.688 5.47253 91.7231 2.4375 95.467 2.4375C100.077 2.4375 103.342 6.93952 101.911 11.3215L99.5945 18.4116C105.577 20.7788 110.806 24.827 114.323 30.3241C117.404 35.1413 118.76 40.4303 118.564 45.6082C118.764 46.9096 118.867 48.2388 118.867 49.5898C118.867 52.797 118.286 55.8817 117.213 58.7628L128.612 69.8964C129.655 70.9148 129.062 72.6587 127.603 72.8657L119.187 74.0594C118.402 74.1708 117.785 74.773 117.671 75.5398L116.449 83.7597C116.237 85.185 114.452 85.764 113.409 84.7456L102.934 74.5142C98.2441 76.9646 92.7982 78.3671 86.9895 78.3671C81.2237 78.3671 75.8155 76.9853 71.1491 74.5684L60.7295 84.7456C59.6869 85.764 57.9015 85.185 57.6896 83.7597L56.4674 75.5398C56.3534 74.773 55.7368 74.1708 54.9518 74.0594L46.5361 72.8657C45.0769 72.6587 44.4841 70.9148 45.5267 69.8964L56.8085 58.877C55.7086 55.9634 55.1119 52.8395 55.1119 49.5898C55.1119 48.8651 55.1416 48.1468 55.1999 47.4356C54.9217 44.8414 55.0405 42.2401 55.5348 39.7042L55.5306 39.7014C56.1895 36.2886 57.5171 33.0832 59.3872 30.1991C61.5503 26.8487 64.4913 23.8585 68.1379 21.4713C68.8478 21.0046 69.58 20.5638 70.3328 20.1504L70.3369 20.1532C70.5355 20.0445 70.7358 19.9375 70.9379 19.8322C71.9996 19.279 73.082 18.7873 74.18 18.3561L71.8819 11.3215Z"
        stroke="#8B5403"
        strokeWidth="3"
      />
      <path
        d="M102.015 11.3215C103.446 6.93952 100.181 2.4375 95.5709 2.4375C91.827 2.4375 88.792 5.47253 88.792 9.21642L88.792 21.7101C88.792 24.3555 90.9365 26.5 93.5819 26.5C95.6541 26.5 97.4914 25.1674 98.1349 23.1976L102.015 11.3215Z"
        fill="#FFD800"
      />
      <path
        d="M71.9858 11.3215C70.5543 6.93951 73.8197 2.4375 78.4296 2.4375C82.1735 2.4375 85.2085 5.47253 85.2085 9.21642L85.2085 21.7101C85.2085 24.3555 83.064 26.5 80.4186 26.5C78.3464 26.5 76.5091 25.1674 75.8656 23.1976L71.9858 11.3215Z"
        fill="#FFD800"
      />
      <path
        d="M113.513 84.7454L84.6834 56.5866C83.9837 55.9031 83.9837 54.7951 84.6834 54.1117L97.3524 41.7373C98.0521 41.0539 99.1865 41.0539 99.8862 41.7373L128.716 69.8962C129.758 70.9145 129.165 72.6584 127.706 72.8654L119.29 74.0592C118.505 74.1706 117.889 74.7728 117.775 75.5395L116.553 83.7595C116.341 85.1847 114.555 85.7638 113.513 84.7454Z"
        fill="#FFAF00"
      />
      <path
        d="M60.8335 84.7454L89.6628 56.5866C90.3625 55.9031 90.3625 54.7951 89.6628 54.1117L76.9938 41.7373C76.2941 41.0539 75.1597 41.0539 74.46 41.7373L45.6307 69.8962C44.5881 70.9145 45.1809 72.6584 46.6401 72.8654L55.0558 74.0592C55.8407 74.1706 56.4573 74.7728 56.5713 75.5395L57.7935 83.7595C58.0054 85.1847 59.7909 85.7638 60.8335 84.7454Z"
        fill="#FFAF00"
      />
      <ellipse cx="87.0934" cy="49.5899" rx="31.8775" ry="28.7774" fill="#EB8C00" />
      <ellipse
        rx="31.0149"
        ry="29.4687"
        transform="matrix(0.886824 -0.462106 0.538873 0.842387 86.9218 44.6564)"
        fill="#FFD800"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.4369 20.1504L117.669 51.8625C117.115 53.7938 116.344 55.6461 115.38 57.3968L65.2252 23.7229C66.8176 22.3778 68.5637 21.1791 70.4369 20.1504ZM59.6863 29.9025L110.413 63.9474C107.805 66.5325 104.681 68.6855 101.187 70.2733L55.6348 39.7013C56.3163 36.1712 57.7134 32.8628 59.6863 29.9025Z"
        fill="#FEED8D"
      />
      <ellipse
        rx="23.6986"
        ry="22.5171"
        transform="matrix(0.886825 -0.462106 0.538873 0.842387 86.9218 44.656)"
        fill="#FFC700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.082 35.9763C108.72 35.235 108.309 34.5038 107.848 33.7859C101.097 23.2654 86.2551 19.6035 74.6978 25.607C63.1405 31.6104 59.2442 45.0058 65.9953 55.5263C66.4451 56.2271 66.9307 56.8975 67.4488 57.5366C62.4992 47.4129 66.6167 35.4015 77.3854 29.8076C88.1728 24.2041 101.822 27.0211 109.082 35.9763Z"
        fill="#FFAF00"
      />
      <path
        d="M77.866 49.5295C74.5704 49.6765 73.3633 47.0137 73.2069 43.6696C73.0506 40.3254 74.0042 37.5663 77.2998 37.4193C80.5954 37.2723 81.8025 39.9351 81.9589 43.2792C82.1152 46.6234 81.1616 49.3825 77.866 49.5295Z"
        fill="#EB8C00"
      />
      <path
        d="M96.3875 49.531C93.0919 49.678 91.8848 47.0152 91.7284 43.671C91.5721 40.3269 92.5257 37.5677 95.8213 37.4208C99.1169 37.2738 100.324 39.9365 100.48 43.2807C100.637 46.6248 99.6831 49.384 96.3875 49.531Z"
        fill="#EB8C00"
      />
    </Svg>
  )
}

export default Prizes
