import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Prizes: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 124 72" {...props}>
      <g opacity="0.2" clipPath="url(#leaderboard_cakers_clip0)">
        <path d="M76 -4L164 -4V76L-4 76.0001L76 -4Z" fill="#F6C081" />
        <path
          d="M67.5447 80.6999C67.5928 80.7507 67.6417 80.8009 67.6913 80.8505C70.8255 83.9847 77.0459 84.2348 81.1546 85.1959C87.4249 86.4951 96.4699 82.8326 103.916 75.3868C111.361 67.941 115.024 58.896 113.725 52.6257L113.731 52.6199L111.808 44.0191L111.79 44.0376C111.635 43.1541 111.385 42.3276 111.039 41.5686L133.371 19.2367C136.074 16.5337 135.633 12.0348 132.458 9.9069C130.409 8.53384 127.639 9.0434 126.213 11.0558L107.342 37.682C100.624 34.2264 88.1576 38.9331 77.8101 49.2807C66.3947 60.6961 61.8444 74.6901 67.5447 80.6999Z"
          fill="#171815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.73 52.619L113.724 52.6248C115.024 58.8951 111.361 67.94 103.915 75.3858C96.4696 82.8316 87.4246 86.4941 81.1543 85.1949C80.1716 84.965 79.068 84.7758 77.9148 84.5781C74.2465 83.9492 70.0756 83.2341 67.691 80.8495C67.6414 80.7999 67.5925 80.7497 67.5444 80.6989C61.8441 74.6891 66.3944 60.6952 77.8098 49.2798C88.1573 38.9322 100.624 34.2255 107.341 37.6811L126.212 11.0549C127.639 9.04252 130.409 8.53296 132.458 9.90602C135.633 12.0339 136.073 16.5328 133.37 19.2358L111.038 41.5677C111.379 42.3138 111.626 43.1252 111.781 43.9918C111.783 44.0022 111.785 44.0125 111.787 44.0229C111.788 44.0275 111.789 44.0321 111.789 44.0366L111.808 44.0182L113.73 52.619ZM113.792 42.1341L116.303 53.3665L116.229 53.4402C116.62 56.8771 115.858 60.7105 114.261 64.4802C112.448 68.7606 109.491 73.1309 105.576 77.0461C101.66 80.9612 97.2901 83.9188 93.0097 85.7317C88.7604 87.5315 84.43 88.2714 80.678 87.494L80.6487 87.4879L80.6195 87.4811C79.7072 87.2677 78.6667 87.0893 77.4836 86.8864L77.361 86.8654C76.1572 86.6588 74.8311 86.4289 73.5234 86.1236C70.9769 85.5291 68.0858 84.5648 66.0308 82.5098C65.9662 82.4452 65.9026 82.3799 65.84 82.3137C62.0932 78.3626 62.0807 72.2525 64.0265 66.2882C66.0146 60.1943 70.2344 53.5346 76.1495 47.6195C81.5163 42.2528 87.4874 38.2895 93.087 36.1155C97.7958 34.2873 102.563 33.6105 106.495 34.8156L124.297 9.69723C126.459 6.64643 130.658 5.87394 133.765 7.95552C138.169 10.907 138.78 17.147 135.031 20.896L113.792 42.1341Z"
          fill="#171815"
        />
        <path
          d="M106.59 38.6792L126.546 10.523C127.772 8.79224 130.155 8.354 131.917 9.53488L133.291 10.4555C136.022 12.2856 136.4 16.1548 134.075 18.4794L110.233 42.3219L106.59 38.6792Z"
          fill="#807C94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.492 41.58L110.233 42.3215L134.075 18.4791C136.4 16.1545 136.022 12.2853 133.291 10.4552L131.917 9.53466C130.873 8.83499 129.611 8.70372 128.501 9.07291C129.514 9.12465 130.515 9.56741 131.251 10.4152L132.018 11.2986C133.499 13.0038 133.462 15.5494 131.932 17.2108L109.492 41.58Z"
          fill="#171815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.528 39.0253L110.26 42.2934L106.786 38.4016L108.373 36.1622L113.528 39.0253Z"
          fill="#171815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.0034 80.7698L72.5212 83.252L81.122 85.1745L81.1279 85.1686C87.3982 86.468 96.4435 82.8056 103.889 75.3596C111.335 67.9136 114.998 58.8684 113.698 52.5981L113.704 52.5928L111.781 43.9921L109.3 46.4736C103.17 43.1782 92.4474 46.7323 83.8547 55.3249C75.2621 63.9175 71.708 74.64 75.0034 80.7698Z"
          fill="#807C94"
        />
        <path
          d="M67.6663 80.8232C73.5899 86.7468 87.7241 82.2166 99.236 70.7047C110.748 59.1928 115.278 45.0584 109.355 39.1348C103.431 33.2112 89.2967 37.7414 77.7848 49.2534C66.2729 60.7653 61.7427 74.8996 67.6663 80.8232Z"
          fill="#C2C1C6"
        />
        <path
          d="M69.6893 78.799C74.9423 84.052 87.6269 79.8842 98.0212 69.4899C108.416 59.0956 112.583 46.411 107.33 41.1579C102.077 35.9049 89.3926 40.0727 78.9983 50.467C68.604 60.8613 64.4362 73.5459 69.6893 78.799Z"
          fill="#171815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92.458 64.4246C90.0852 73.2798 82.0352 78.8168 74.4777 76.7918C71.5736 76.0136 69.1648 74.2321 67.4341 71.8307C67.724 65.6969 71.9157 57.5506 78.9985 50.4678C80.9125 48.5538 82.9041 46.851 84.9126 45.3792C91.2912 48.2404 94.6302 56.3178 92.458 64.4246Z"
          fill="#171815"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.576 46.6288C103.496 43.0714 92.578 46.6017 83.8547 55.325C75.1322 64.0476 71.6017 74.9647 75.1575 81.0448C81.4164 81.4489 90.3438 77.1683 98.0216 69.4905C105.698 61.8137 109.979 52.8876 109.576 46.6288Z"
          fill="#312337"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 60.7882L79.2436 60.7882C78.509 61.8317 77.8443 62.885 77.252 63.9383L102.842 63.9383C103.625 62.891 104.345 61.8381 105 60.7882ZM98.2479 69.2629C98.7969 68.7072 99.3282 68.1453 99.8413 67.5785L75.4918 67.5785C75.2629 68.1439 75.0564 68.7059 74.8727 69.2629L98.2479 69.2629Z"
          fill="#3D384F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.398 49.9261C109.809 46.6038 109.169 43.8079 107.33 41.9681C102.076 36.7151 89.0295 41.2453 78.1881 52.0866C75.2707 55.0041 72.8102 58.0813 70.8745 61.1234C72.8326 57.5541 75.5783 53.8866 78.9979 50.467C89.3922 40.0727 102.077 35.9049 107.33 41.1579C109.343 43.1713 109.973 46.2765 109.398 49.9261Z"
          fill="#010101"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.6894 75.077C74.4621 69.2307 78.1891 62.2038 84.2597 56.1332C91.2921 49.1008 99.6078 45.2135 105.885 45.4205C100.167 44.0971 91.305 47.8723 83.8531 55.3242C77.3312 61.8461 73.6256 69.4478 73.6894 75.077Z"
          fill="#010101"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.7529 83.0941C78.8714 85.0676 90.4372 80.153 100.205 70.3853C104.417 66.1727 107.727 61.6257 109.971 57.2452C107.893 61.9528 104.487 66.9108 99.9634 71.4346C90.3232 81.0747 78.711 85.639 71.7529 83.0941Z"
          fill="#010101"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.1311 81.9109C75.4852 83.7686 70.6502 83.4377 67.8509 80.6383C65.0515 77.839 64.7206 73.0041 66.5782 67.3582C68.4307 61.7281 72.4385 55.3732 78.1718 49.6399C83.9051 43.9066 90.2601 39.8989 95.8902 38.0464C101.536 36.1887 106.371 36.5197 109.17 39.319C111.97 42.1184 112.301 46.9533 110.443 52.5992C108.591 58.2292 104.583 64.5842 98.8494 70.3175C93.1161 76.0507 86.7611 80.0585 81.1311 81.9109ZM99.0339 70.5019C87.522 82.0138 73.4783 86.6346 67.6664 80.8228C61.8545 75.011 66.4754 60.9673 77.9873 49.4554C89.4993 37.9436 103.543 33.3227 109.355 39.1346C115.167 44.9464 110.546 58.99 99.0339 70.5019Z"
          fill="#010101"
        />
        <path d="M129.282 12.1269C128.513 8.57499 122.376 19.0327 111.615 34.1748L129.282 12.1269Z" fill="#C2C1C6" />
        <path
          d="M76.725 -33.8392C75.8507 -18.4216 67.3556 -8.95483 52.8652 -0.656517C41.3685 5.92743 29.7082 8.56759 24.8315 19.3391C15.1684 40.6823 23.2389 59.8991 42.587 68.7241C61.9351 77.549 83.8882 70.8579 93.5513 49.5146C101.272 32.4608 101.948 25.5525 96.859 6.02106C94.7309 12.1714 91.9463 20.3581 83.657 24.7724C83.657 24.7724 91.2968 -19.7153 76.725 -33.8392Z"
          fill="#ED8103"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.4287 -39.7211L78.5522 -35.7244C82.7388 -31.6665 85.1438 -25.6423 86.5215 -19.2781C87.9108 -12.8611 88.3257 -5.76474 88.2807 0.781081C88.2357 7.34208 87.7275 13.4396 87.2323 17.8908C87.1923 18.2507 87.1523 18.6 87.1126 18.9381C90.9448 15.0068 92.7609 9.83526 94.3778 5.16255L97.2133 -3.03216L99.3996 5.35912C101.959 15.1829 103.16 22.1461 102.626 28.9038C102.091 35.6757 99.8318 42.0081 95.943 50.5975C85.6451 73.3429 62.0874 80.5042 41.4974 71.1128C31.2863 66.4554 23.8798 58.9667 20.3728 49.651C16.8631 40.328 17.3663 29.4618 22.4396 18.2562C25.2022 12.1543 29.8741 8.45048 35.0652 5.49025C37.52 4.09033 40.158 2.8205 42.7899 1.55359C42.9204 1.49079 43.0509 1.42798 43.1813 1.36517C45.9719 0.0213241 48.7777 -1.34124 51.5604 -2.93484C58.6451 -6.99209 64.0604 -11.2232 67.8094 -16.1649C71.5217 -21.0584 73.6945 -26.7726 74.1036 -33.9878L74.4287 -39.7211ZM86.6014 22.8875C85.7046 23.5703 84.7266 24.2027 83.6569 24.7724C83.6569 24.7724 83.869 23.5373 84.1551 21.4132C85.4832 11.5543 88.4059 -17.4556 79.1129 -30.9873C78.3925 -32.0363 77.5986 -32.9923 76.7249 -33.8392C76.6557 -32.6196 76.5389 -31.4372 76.3752 -30.2898C74.4692 -16.9312 66.2093 -8.29839 52.8651 -0.656502C49.866 1.06106 46.8557 2.51023 43.9576 3.90538C35.7464 7.85828 28.436 11.3776 24.8314 19.3391C15.1683 40.6823 23.2388 59.8991 42.5869 68.724C61.935 77.549 83.8881 70.8578 93.5512 49.5146C100.633 33.8734 101.788 26.7666 98.0042 10.6279C97.6625 9.17026 97.2805 7.63898 96.8589 6.02108L96.8141 6.15066C96.3461 7.50346 95.845 8.95193 95.2599 10.4351C93.5362 14.8043 91.0837 19.4747 86.6014 22.8875Z"
          fill="#171815"
        />
        <path
          d="M73.5735 -7.92095C56.6619 8.52492 36.4483 7.76286 28.9697 24.2871C20.8993 42.1191 27.6417 58.1754 43.8039 65.5498C59.966 72.9243 82.5834 66.6006 84.9694 47.1733C87.0201 30.4774 75.352 27.0562 78.0831 9.38742C78.0831 9.38742 71.1331 13.3015 70.1333 22.4809C70.1333 22.4809 65.6043 13.7246 73.5735 -7.92095Z"
          fill="url(#leaderboard_cakers_paint0_linear)"
        />
        <path
          d="M87.7204 44.8459C91.8181 52.2151 84.5414 60.0599 74.8508 65.0375C65.1602 70.015 57.6955 70.5857 52.781 63.7462C47.2632 56.0672 55.1428 59.2154 63.6083 50.3492C72.0739 41.483 82.3828 35.247 87.7204 44.8459Z"
          fill="#71380D"
        />
        <path
          d="M55.9601 63.745C64.5618 65.5942 73.7847 60.2734 73.7847 60.2734C73.7847 60.2734 71.7359 63.302 68.6744 64.1583C68.6744 64.1583 69.9835 64.6717 73.3947 64.0257C70.637 65.5336 65.0777 67.7805 60.2122 66.9268C58.4731 66.9756 55.9601 63.745 55.9601 63.745Z"
          fill="#AC5F05"
        />
        <path
          d="M83.2788 43.6946C86.3563 48.1735 80.8288 54.83 72.4312 59.1435C64.0335 63.4569 54.3272 63.0593 53.765 60.6928C53.2029 58.3262 61.8189 56.4052 67.3509 50.0519C72.8828 43.6987 81.3062 40.8237 83.2788 43.6946Z"
          fill="#EDAB03"
        />
        <path
          d="M82.3373 47.5522C81.9731 51.0529 75.1053 55.1376 73.5859 56.1171C73.5859 56.1171 77.1687 52.6439 77.8378 51.1636C75.2182 52.8111 73.2794 52.8655 73.2794 52.8655L76.9091 48.9413C73.9015 50.5996 69.9185 51.7232 68.7787 51.5304C68.7787 51.5304 75.8156 48.438 79.2818 45.9517C81.3267 44.4849 82.5133 45.8609 82.3373 47.5522Z"
          fill="#FFC73C"
        />
        <path
          d="M25.9188 50.6185C29.9448 55.043 36.1531 57.0014 38.7539 57.4275C38.7539 57.4275 35.972 59.5193 32.0165 58.3574C29.4504 57.6036 26.5637 53.0567 25.9188 50.6185Z"
          fill="#FFC73B"
        />
        <path
          d="M23.9053 44.0873C28.9356 51.8808 38.087 54.4541 42.2229 55.1055C42.2229 55.1055 38.2634 59.1657 32.0592 57.4006C28.0344 56.2555 24.5408 47.7642 23.9053 44.0873Z"
          fill="#ED8103"
        />
        <path
          d="M42.2604 55.3196C42.2604 55.3196 27.2285 53.4693 25.1138 45.81C25.1138 45.81 30.6303 49.1712 31.6537 48.7902C31.6537 48.7902 29.2669 47.0855 28.8868 45.2272C33.3102 48.9416 36.2865 48.8598 38.291 50.2468C43.189 53.636 42.2604 55.3196 42.2604 55.3196Z"
          fill="#ED8103"
        />
        <path
          d="M26.1117 48.1351C30.1377 52.5596 36.3459 54.518 38.9468 54.9441C38.9468 54.9441 36.1649 57.0359 32.2094 55.874C29.6433 55.1202 26.7566 50.5733 26.1117 48.1351Z"
          fill="#AC5F05"
        />
        <path
          d="M26.5112 48.251C30.8322 52.2813 37.8429 54.0965 40.8081 54.5003C40.8081 54.5003 37.8848 56.4275 33.4119 55.3487C30.5101 54.6488 27.0798 50.4609 26.5112 48.251Z"
          fill="#EEEDF2"
        />
        <path
          d="M68.3307 28.246C67.1133 37.8213 59.2864 47.4733 55.5251 51.1024C55.5251 51.1024 62.6641 49.8987 67.756 43.8392C71.0593 39.9083 70.2511 31.5979 68.3307 28.246Z"
          fill="#FFC73B"
        />
        <path
          d="M63.6589 18.9183C64.9085 33.2636 52.9877 47.1619 46.9746 52.9053C46.9746 52.9053 58.4602 52.3306 66.5168 42.8867C71.7433 36.7602 67.1098 23.5609 63.6589 18.9183Z"
          fill="#ED8103"
        />
        <path
          d="M47.1746 53.1754C47.1746 53.1754 69.6624 32.8447 63.7761 22.2068C63.7761 22.2068 58.804 32.4873 56.6529 33.3386C56.6529 33.3386 58.498 28.6272 56.8508 26.209C54.1106 35.5237 49.1099 39.0781 47.5059 42.9879C43.5863 52.5415 47.1746 53.1754 47.1746 53.1754Z"
          fill="#ED8103"
        />
        <path
          d="M64.9777 25.8728C63.7603 35.4482 55.9334 45.1003 52.1721 48.7294C52.1721 48.7294 59.3111 47.5257 64.403 41.4662C67.7063 37.5352 66.8981 29.2247 64.9777 25.8728Z"
          fill="#AC5F05"
        />
        <path
          d="M64.4618 26.483C62.2764 36.005 52.953 46.488 48.5645 50.5393C48.5645 50.5393 55.7352 48.9898 61.7806 42.3846C65.7025 38.0997 66.2285 29.5016 64.4618 26.483Z"
          fill="#FFC73B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.0304 38.6941C64.0341 37.8172 63.9762 36.9152 63.8518 35.997C63.6389 34.4254 63.2479 32.9296 62.7141 31.549C59.7059 38.2554 54.2649 44.7984 50.4669 48.6819C50.8687 48.981 51.2823 49.2465 51.7051 49.4757C54.3506 48.3665 58.2561 46.2342 61.7794 42.3848C62.72 41.3572 63.4652 40.0814 64.0304 38.6941Z"
          fill="#F4F2F0"
        />
      </g>
      <defs>
        <linearGradient
          id="leaderboard_cakers_paint0_linear"
          x1="44.9115"
          y1="-0.453153"
          x2="62.8023"
          y2="68.2131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC73C" />
          <stop offset="1" stopColor="#FE9A03" />
        </linearGradient>
        <clipPath id="leaderboard_cakers_clip0">
          <rect width="124" height="72" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Prizes
